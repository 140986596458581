import React from 'react';
import "./Faq.css";

// This the faq data, it is mapped to HTML elements below!
// The reason for this as an array is that we can extract into a rest call later down the line, if we introduce a server
const faqData = [
  { "header": "1. There's a new channel and discord server?",
    "body": "As of October 2023, Bombers Bar has moved to new services. The new in-game main channel is \"BB: Bombers Bar\" and the link to the new discord server can be found on the website homepage. You can leave the old main channel and discord if you're still in them as those will no longer be used or updated."
  },
  {
    "header": "2. How to join us on TS?",
    "body": "There is a fixed address and password in the \"BB: Bombers Bar\" channel that you should use to join our Teamspeak server. When you initially connect you will be in the \"Main Lobby\". From here you should be able to join the various public channels and hang out with your fellow cloaky space nerds. When a fleet is starting and you x up in the BB in-game channel, you are called into fleet. Switch to the in-game Fleet chat window, in the MOTD you will see the relevant TS channel to use (usually BB Roam 1) together with new password. Join us there!"
  },
  {
    "header": "3. TS password is not working?",
    "body": "The FC has a lot to do and is probably a little behind. Move to the staging system and just put a reminder in the fleet chat window and your FC will update it shortly in the fleet MOTD"
  },
  {
    "header": "4. What is an X-up?",
    "body": "Simple, just type an \"x\" in a channel. An X-up channel for joining fleet will be linked in the main channel when fleet is starting and while fleet is still accepting members. The FC might ask you to volunteer in the fleet channel for one reason or another, too."
  },
  {
    "header": "5. My mic is offline",
    "body": "We don’t like people having muted mic's. Offline mic's may be a clear signal to us that you are speaking in some other channel, to some other group… So expect to be asked to unmute your mic if caught"
  },
  {
    "header": "6. MOTD (Message Of The Day)",
    "body": "In your fleet chat window have a look at the MOTD. Be sure to reload it often because info will change from time to time. Reload by left-clicking small donut icon on the top and clicking \"Reload MOTD\". The MOTD has some important info such as; who is the fleet BLOPS (Black Ops ship that will bridge us to the target), who is the FC and what staging system you should travel to. The BLOPS and FC pilots are two most important characters in fleet, you should put them in your WATCHLIST. To do this; Rightclick the name of the BLOPS pilot, in that menu open pick “Fleet” then “Add to Watchlist”, repeat for the FC or anybody else you want keep an eye on. You can drag and drop them from the fleet chat window to watchlist also. Keep it as simple as possible and the BLOPS pilot should always be put on the top of the list."
  },
  {
    "header": "7. Fleet Types",
    "body": "The general fleet types we run are; Whaling, Amarda, Habakuk, Wolfpack and Bombing fleets. Click here to view the Fleet Types Page which explains each fleet type in more detail."
  },
   {
    "header": "8. Overview that I should have?",
    "body": "There are many very good ones around. Usability depends on what you are doing in EVE. I will list a few here and you can take your pick: Z-S Overview Hyperlink to https://github.com/Arziel1992/Z-S-Overview-Pack Other Overview Other Overview Drop a message in the in-game Bombers Bar channel if you are stuck and need a hand setting up."
  },
  {
    "header": "9. To cloak or not to cloak?",
    "body": "Once you arrive to the staging system displayed in the MOTD, stay cloaked in system or dock at the station / citadel specified in the MOTD. At some point the FC will call us all to warp to the BLOPS pilot. YOU NEED TO BE CLOAKED, check that before you warp to the BLOPS! If you warp uncloaked, you will uncloak all others in the fleet and put the whole fleet in danger. If you repeatly uncloak the fleet you will be asked to leave. Dont be \"that\" guy."
  },
  {
    "header": "10. Bridge is up!",
    "body": "When the hunter finds a target he will light a covert cyno. Our BLOPS will lock on to that cyno and will open the bridge for our fleet. To take the bridge you need to; Keep at range 1000m to the BLOPS, when the blue vortex appears around BLOPS or the bridger says \"bridge up!\" You need to either leftclick BLOPS name in watchlist, which will open radial navigation and use top field (at 12:00 hours) to jump, or you can right click the BLOPS pilot and from menu use Jump to… (the name of the system where hunter opened cyno)."
  },
  {
    "header": "11. JUMP, JUMP, JUMP ! Possible problems?",
    "body": "Since everybody is cloaked, you never know if you warped at zero to the BLOPS. If in doubt, warp away to random celestial and warp back to the BLOPS at zero again. Better then to be lazy and once everybody decloak for jump, you find yourself 100 km away. You will miss the kill! Every time someone decloaks on the BLOPS (this will happen, yes). The whole fleet needs to warp off to random celestial, and wait until BLOPS calls the fleet back. (bold)DO NOT go before that or you may end in some strange place."
  },
  {
    "header": "12. Finally! Jumping on target",
    "body": "Finally, we jump on the target. First, ALIGN to some random celestial. A moon, green anomaly, asteriod belt. Just, be ready to run away if needed. ALIGN! Then, lock the target. And then, ALWAYS FIRST APPLY POINT (or any other e-war on the target). DO NOT start with DPS i.e. torpedos before FC calls for it. Keep in mind, BB fleet usually have 20,000-30,000 DPS and can melt down most of the targets in a minute or seconds. Just as you want to get on juicy killmail, so do 50 other pilots in your fleet. If you are among the first to drop on the target and you start full DPS, chances are first 10-20 pilots will kill the target before others have even lock it (because they were slow in taking the bridge). Imagine the other 30 pilots disappointment. Maybe you will be among those on other drops? You secure your place on killmails the moment you point or scram it, not when you shoot torpedos on it. Strange thing, is it? BB fleet’s major problem is to control DPS and not kill target, not vice versa. Once we kill ship, lock the pod. E-WAR ONLY ON THE POD! – FC screams every time… All of us wants to be on that killmail, too. DO NOT shoot torpedos at the pod, not all of us can lock it as quick as you have. Be generous, make others happy."
  },
  {
    "header": "13. Advice on how to stop killing prematurely",
    "body": "If you are using a mouse to start modules then move your point (warp disruptor) and dampening modules to upper row – you can click on those first when the hype starts. Torpedo icon move from central F1 position, so you will be less tempted to click it in hurry. Also, be wise. Towards the end of target’s life leave one damp mod free to use on pod and get on pod killmail. You will be a whore, I know… But it pays off."
  },
  {
    "header": "14. Target killed, pod killed \\o/ hurray, now what?",
    "body": "Well, congatz fellow bomber pilot. Now is a time to warp to a random celestial if the FC calls for it and cloak on the way to it. Cloak while you warp off, NOT at the moment you arrive at the celestial. Reason: you just kill expensive ship, he was screaming loud while dying and his friends in the system will try to find individual targets (our bombers) to make things even. Once you are cloaked and safe, wait. Our BLOPS didn’t jump with us on the target and is still in the old system together with the fuel truck and maybe some individuals that were slow to get the bridge in time. Now, they will start to communicate in comms, calling for everyone left in the previous system to warp to the BLOPS before all of them join us in the system we just dropped in. We wait until BLOPS jumps and calls us all to warp to zero. Then the hunters go out to find us more juicy targets to kill."
  },
  {
    "header": "15. I like it, want to be with you and help, I will bring a bling fit bomber, combat BLOPS maybe, everything to help you…",
    "body": " Just remember anything outside of ObomberCare will not be covered. Polarized bombers are normally flown by experianced pilots so you know the risks. The same applies to combat blops. Bridging BLOPS are something else. We need bridging BLOPS, but let’s make it clear – you will not end up on any killmail, You may not be paid for it (we pay for fuel), your FC most likely will share his FC payment with you, the risk is high and you will put your ship in danger…. If you want to help, go for recons, we need those in any fleet. They keep bombers alive. Ultimately, go hunting, become a hunter-star. Loss of you hunting ship is covered by O’Bomberscare, you can use a Mar5hy bling fit to stay alive long enough to light a cyno… and maybe become famous in EVE. They will fear you and avoid you even if you fly any ship, because you never know – maybe there are hundred ships on the other side waiting? Happy hunting, guys…"
  }
];

function FaqView() {
  return (
    <div className="contentContainer faqContainer">
    <h1>FAQ</h1>
      { faqData.map((item, index) => (
        <div className="faqData" key={index}>
          <h3 className="faqHeader">{item.header}</h3>
          <p className="faqBody">{item.body}</p>
        </div>
      )) }
    </div>
  );
}

export default FaqView;
